.pi-root {
    padding: 4px 4px 0px 4px;
  }
  
  .p-dashed-divider {
    border: 1px dashed var(--line-border-fill-1);
    border: 1px dashed #e0e0e0;
    height: 0px;
    opacity: 1;
  }
  
  .pi-paper {
    margin: 0 !important;
    padding: 10px 16px;
    border-radius: 8px !important;
    background: white;
    box-shadow: 0px 10px 25px #0000000a !important;
    height: 100% !important;
    border: 1px solid #d3d3d361 !important;
  }
  .pi-paper1 {
    margin: -18px 10px 40px !important;
    padding: 10px 16px;
    border-radius: 8px !important;
    background: white;
    box-shadow: 0px 10px 25px #0000000a !important;
    border: 1px solid #d3d3d361 !important;
    width: 205px !important;
  }
  
  .pi-title {
    font-size: 12px !important;
    font-weight: 600 !important;
    display: inline-block;
    color: #101010;
    font-family: "pc_semibold" !important;
  }
  
  .pi-addbtn {
    float: right;
    color: #0071f2;
    cursor: pointer;
    font-size: 14px !important;
    /* font-weight: 600 !important; */
    font-family: "pc_medium" !important;
  }
  .pi-addbtn2 {
    margin-right: 55%;
    color: #0071f2;
    cursor: pointer;
    font-size: 14px !important;
    /* font-weight: 600 !important; */
    font-family: "pc_medium" !important;
  }
  
  .pi-addbtn1 {
    /* float: right; */
    color: #0071f2;
    cursor: pointer;
    font-size: 12px !important;
    font-family: "pc_medium" !important;
    /* font-weight: 600 !important; */
  }
  
  .pi-actions-root {
    padding-top: 10px;
  }
  
  .pi-names-root {
    padding-bottom: 11px;
    display: grid !important;
    /* grid-template-columns: 11% 11% 21% 21% 21% 13% 11%; */
    grid-template-columns: 11% 11% 18% 18% 19% 13% 11% !important;
  }
  
  .pi-basic-details {
    display: grid !important;
    /* grid-template-columns: 18% 18% 18% 18% 7% 18% 18%; */
    grid-template-columns: 18% 23% 18% 20% 18% 7%;
  }
  
  .add_new_col_css {
    display: grid !important;
    grid-template-columns: 50% 50%;
  }
  
  .pi-upload-root {
    display: flex;
  }
  
  .pi-upload-root > label {
    box-shadow: unset;
    margin: auto;
    margin-bottom: 5px;
    margin-left: 2px;
  }
  
  .pn-row-root {
    grid-template-columns: 180px 1fr;
    display: grid !important;
  }
  
  .pn-image-root {
    padding: 0 !important;
  }
  
  .pn-image-paper-root {
    padding: 0px;
  }
  
  .pn-image-paper-root > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .pn-names-root {
    padding: 0 0 0 4px;
  }
  
  .pn-names-actions {
    display: flex;
  }
  
  .pn-names-actions > div {
    margin: auto;
    margin-bottom: 12px;
  }
  
  .pn-names-actions > div > span {
    font-size: 12px !important;
    padding-right: 10px;
  }
  
  .pn-names-actions > div > i {
    font-size: 15px !important;
    padding-right: 10px;
  }
  
  .pn-image {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 10px !important;
    height: 159px !important;
  }
  
  .pn-upload-actions {
    padding: 10px 10px 10px;
    border-top: 1px solid #e0e0e0;
  }
  
  .pn-upload-actions > label {
    border: 1px solid lightgray;
    color: lightgray;
    font-weight: bold;
    width: 100%;
  }
  
  .pn-upload-actions > label > p {
    color: #7b7b7b;
  }
  
  .pn-upload-actions-btn {
    width: 100%;
    background-color: unset !important;
    color: black !important;
    border-color: #b6b6b6 !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    color: #6f6f6f !important;
    font-family: "pc_medium" !important;
  }
  
  .aa-actions-btn-root {
    text-align: center !important;
    padding-top: 15px !important;
    padding-bottom: 20px !important;
  }
  
  .aa-actions-btn {
    margin-right: 15px;
  }
  
  .p-continue-action {
    text-align: center;
    padding: 20px 20px 44px 20px;
    float: right;
  }
  
  .sl-root {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: #f7f7f7;
  }
  
  .sl-detail-root {
    display: grid !important;
    grid-template-columns: 38px 1fr;
    padding: 0;
  }
  
  .sl-count {
    display: flex;
  }
  
  .sl-count > span {
    margin: auto;
    margin-left: 0;
    padding: 6px 10px 6px 7px;
    background: #e0e0e0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    /* border-radius: 8px 0px 0px 8px; */
  }
  
  .sl-details {
    padding: 10px 15px 10px 0px;
    display: grid !important;
    grid-template-columns: 45px 1fr;
  }
  
  .sl-detail {
    padding: 10px 15px 10px 0px;
  }
  
  .sl-details-avatar {
    width: 35px !important;
    height: 35px !important;
    margin: 0 !important;
  }
  
  .sl-details-names {
    display: flex;
    flex-direction: column;
  }
  
  .sl-details-names > h6 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 3px;
    line-height: 1;
    font-family: "pc_medium" !important;
  }
  
  .sl-details-names > span {
    font-size: 12px;
    font-family: "pc_medium" !important;
  }
  
  .sl-action-root {
    display: flex;
  }
  
  .sl-action-root > div {
    margin: auto;
    margin-right: 0px;
  }
  
  .sl-action-btn {
    font-size: 18px !important;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .pa-showList {
    padding-top: 15px;
  }
  
  .fUWbfp,
  .gnnbCe,
  .default-size {
    font-size: 12px !important;
    color: #6f6f6f !important;
    margin: 0px !important;
    margin-bottom: 6px !important;
  }
  
  .ad-arrow {
    border-radius: 50%;
    border: 1px solid;
    padding: 2px 5px 3px 5px;
    color: black;
    font-weight: bold !important;
    margin-left: 11px;
  }
  
  .ad-tick {
    border-radius: 50%;
    border: 1px solid;
    padding: 4px 5px 4px 5px;
    color: white;
    background-color: #0080009c;
    /* font-weight: bold !important; */
    margin-left: 11px;
  }
  
  .pi-upload-doc {
    display: grid;
    grid-template-columns: 24px 1fr 36px 13px;
    width: 247px;
    border: 1px solid #a7a4a4;
    border-radius: 7px;
    background: #f3f3f33d;
    height: 42px;
    padding: 9px;
    margin-top: 20px;
  }
  
  .pi-upload-doc-img {
    height: 20px !important;
    width: 20px !important;
  }
  
  .pi-upload-doc-close {
    margin-top: 3px;
    cursor: pointer;
  }
  
  .width_list {
    width: 50% !important;
  }
  
  .drawerSubHead {
    font-family: "poppin";
    color: "#6F6F6F";
    font-size: "8px";
    padding-bottom: "8px";
  }
  .mt {
    margin-top: 20px;
  }
  